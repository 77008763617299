export const STATUS_MAP = {
  CARETEAMRLTNSTATUS_NEW: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.new'),
  CARETEAMRLTNSTATUS_NEWTOYOU: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.new_to_you'),
  CARETEAMRLTNSTATUS_ESTABLISHED: I18n.t(
    'patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.established'
  ),
  CARETEAMRLTNSTATUS_INACTIVE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.inactive'),
  CARETEAMRLTNSTATUS_TERMINATED: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status.terminated')
};

export const STATUS_DEFINITION = {
  NEW: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status_definitions.new'),
  NEW_TO_YOU: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status_definitions.new_to_you'),
  ESTABLISHED: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status_definitions.established'),
  INACTIVE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status_definitions.inactive'),
  TERMINATED: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_status_definitions.terminated')
}

export const TERMINATE_REASONS_MAP = {
  PLANNOTESUBTYPE_COMPLETETREATMENTPLAN: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.complete_treatment_plan'),
  PLANNOTESUBTYPE_NEEDINPERSONCARE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.need_in_person_care'),
  PLANNOTESUBTYPE_PREFERENCEINPERSONCARE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.preference_in_person_care'),
  PLANNOTESUBTYPE_RFRRLTOANTHRTELADOCPRVDR: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.referral_another_teladoc_provider'),
  PLANNOTESUBTYPE_NONRESPONSE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.nonadherence_or_noneresponse'),
  PLANNOTESUBTYPE_CHANGEINCOVERAGE: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.change_in_coverage'),
  PLANNOTESUBTYPE_INAPPROPRIATEBEHAVIOR: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.inappropriate_behavior'),
  MEMBER_INITIATED_SWITCH_TERMINATION: I18n.t('patient_case_load_mgmt_mvp.patient_list.care_team_relation_termination_status.member_initiated_switch_termination')
}

export const DEFAULT_SORTING_COLUMN = 'personName';

export const DEFAULT_SORTING_DIRECTION = 'asc';

export const DEFAULT_FILTERS = {
  status: [
    'CARETEAMRLTNSTATUS_NEW',
    'CARETEAMRLTNSTATUS_NEWTOYOU',
    'CARETEAMRLTNSTATUS_ESTABLISHED'
  ]
};
