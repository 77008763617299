import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';

const TeladocModal = ({ onClose, isOpen, children, showCloseButton, title, actions, className, closeIcon, contentClassName }) => {
  if (!isOpen) return null;
  return (
    <div className={`teladocModalWrapper ${className}`}>
      <div className="teladocModalContainer">
        <div className={`teladocModalContent ${!!contentClassName ? contentClassName : ''}`}>
          <div className="teladocModalHeader">
            <span className="teladocModalTitle">{title}</span>
            {showCloseButton && (
              <span
                className={`teladocModalCloseButton ${!closeIcon ? 'defaultCloseIcon' : null}`}
                onClick={() => onClose()}
              >
                {closeIcon && <img className="closeIcon" key="closeIcon" src={closeIcon} />}
              </span>
            )}
          </div>
          <div className="teladocModalBody">{children}</div>
          {actions && actions.length && (
            <div className="teladocModalActions">
              {map(actions, action => {
                let actionClassNames = 'action declineDecline';
                if (action.className) actionClassNames = `${actionClassNames} ${action.className}`;
                actionClassNames = action.primary
                  ? `${actionClassNames} acceptAction`
                  : `${actionClassNames} declineAction`;
                return (
                  <button
                    key={action.className || action.label}
                    className={actionClassNames}
                    onClick={() => action.onClick()}
                    disabled={action.disabled}
                  >
                    {action.label}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TeladocModal.propTypes = {
  children:        PropTypes.node.isRequired,
  title:           PropTypes.string.isRequired,
  isOpen:          PropTypes.bool,
  showCloseButton: PropTypes.bool,
  className:       PropTypes.string,
  onClose:         PropTypes.func,
  actions:         PropTypes.arrayOf(
    PropTypes.shape({
      label:     PropTypes.string.isRequired,
      className: PropTypes.string,
      primary:   PropTypes.bool,
      disabled:  PropTypes.bool,
      onClick:   PropTypes.func.isRequired
    })
  )
};

TeladocModal.defaultProps = {
  isOpen:          false,
  showCloseButton: true
};

export default TeladocModal;
